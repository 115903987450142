import './History.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../components/UI/page-head/Head";
import $api from "../../api";
import uzcard from '../../assets/images/uzcard.png'
import payeer from '../../assets/images/payeer.png'
import {Link} from "react-router-dom";
import {formatPrice} from "../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";

const History = () => {

    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/payment-order/my-history?page=0&size=20', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
    }, [])


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className='history page'>
                <Head />
                <div className="history__body body">
                    <div className='body__titles'>
                        <h2 className="title">{ t('history.title') }</h2>
                        <span className='txt'>{ t('history.activities') }</span>
                    </div>
                    <ul className='body__list'>
                        {
                            result.success ?
                                result.data.length > 0 ?
                                    result.data.map(i => (
                                        <li className='body__item item' key={i.id}>
                                            <Link className='row between align-center' to={`:${i.id}/:${i.income}/:${i.payType}`}>
                                                <div className='row align-center'>
                                                    <div className="item__imgs">
                                                        {
                                                            i.payType === 'PAYEER' ?
                                                                <img className='img' src={payeer} alt="img"/>
                                                                :    <img className='img' src={uzcard} alt="img"/>
                                                        }
                                                    </div>
                                                    <div className="item__titles">
                                                        <span className='name'>{ i.payType }</span>
                                                        <span className='date'>{ new Date(i.date).toLocaleDateString() }</span>
                                                    </div>
                                                </div>
                                                <div style={{textAlign: 'end'}}>
                                                    <span className={`amount ${i.status === 'REJECT' ? 'red' : i.status === 'PAYED' || i.status === 'ACCEPT' ? 'green' : ''}`}>
                                                        { i.income ? '+' : '-' }{ formatPrice(i.amount) } uzs
                                                    </span>
                                                    <span className='txt'>{ i.income ? t('history.deposit') : t('history.withdraw') } { i.status === 'REJECT' ? t('history.reject') : i.status === 'PAYED' || i.status === 'ACCEPT' ? t('history.success') : t('history.pending') }</span>
                                                </div>
                                            </Link>
                                        </li>
                                    )) : <p className='loading red'>No cards !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default History;
