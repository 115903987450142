import './Awards.scss'
import React, {useEffect, useState} from 'react';
import trophy from '../../assets/images/trophy.svg'
import $api from "../../api";
import {formatPrice} from "../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";

const Awards = () => {

    const [active, setActive] = useState(true)

    // get data
    const [awards, setAwards] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/user/get-top-${active ? 'deposit' : 'referral'}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setAwards(res.data)
            })
    }, [active])


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="team page">
                <div className="team__head head">
                    <div>
                        <img className='head__img' src={trophy} alt="icon"/>
                        <h2 className="head__title">{ t('awards.title', {nav: active ? t('awards.deposit') : t('awards.referral')}) }</h2>
                    </div>
                    <div className='head__nav'>
                        <button
                            className={`btn ${active ? 'active' : ''}`}
                            onClick={() => setActive(true)}
                        >
                            { t('awards.challenge', {nav: t('awards.deposit')}) }
                        </button>
                        <button
                            className={`btn border ${!active ? 'active' : ''}`}
                            onClick={() => setActive(false)}
                        >
                            { t('awards.challenge', {nav: t('awards.referral')}) }
                        </button>
                    </div>
                </div>
                <div className="team__body body">
                    <div className="body__txts">
                        <span className='txt'>{ t('awards.user_name') }</span>
                        <span className='txt'>{ t('awards.amount', {nav: active ? t('awards.deposit') : t('awards.referral')}) }</span>
                    </div>
                    <ul className="body__list">
                        {
                            awards.success ?
                                awards?.data?.length > 0 ?
                                    awards?.data.map((i, num) => (
                                        <li className='body__item item'>
                                            <span className='item__id txt'>{ num+1 }</span>
                                            <span className='item__name txt'>{ i.user.name }</span>
                                            {
                                                active ?
                                                    <span className='txt__money'>
                                                        { i.depositAmount !== null ? formatPrice(i.depositAmount) : 0 } uzs
                                                    </span>
                                                    :
                                                    <span className='txt__money'>
                                                        { i.profit !== null ? formatPrice(i.profit) : 0 }
                                                    </span>
                                            }
                                            <div className='item__icon'/>
                                        </li>
                                    ))
                                    : <p className='loading red'>No data !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Awards;
