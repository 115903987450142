import axios from 'axios';
import i18next from "i18next";

export const API_URL = `https://cashkoo.vip/`

const $api = axios.create({
    baseURL: API_URL,
    headers: {
        'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Accept-Language': i18next?.resolvedLanguage
    }
})

export default $api