import '../Deposit.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../../../components/UI/page-head/Head";
import depositImg1 from '../../../../assets/images/humo-uzcard.jpg'
import Deposit2 from "./Deposit2";
import Deposit3 from "./Deposit3";
import DepositWaiting from "./DepositWaiting";
import {toast} from "react-hot-toast";
import $api from "../../../../api";
import {formatPrice} from "../../../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";

const Deposit = () => {

    const me = JSON.parse(localStorage.getItem('me'))
    const content = JSON.parse(localStorage.getItem('content'))

    const [page, setPage] = useState(1)
    const [effect, setEffect] = useState(false)

    const [amount, setAmount] = useState(localStorage.getItem('amount'))
    const [phoneNumber, setPhoneNumber] = useState(me?.phoneNumber)


    const [cardNumber, setCardNumber] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/trade-order/generate-card', {
                params: {
                    amount: localStorage.getItem('amount')
                },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setCardNumber(res.data)
            })
            .catch(err => {
                setCardNumber(err.response.data)
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [effect])

    const goNextPage = (e) => {
        e.preventDefault()

        if (amount >= content?.minDepositP1 && phoneNumber.length > 8 && cardNumber.success) {
            localStorage.setItem('amount', amount)
            setEffect(prev => !prev)
            setPage(prev => prev + 1)
        } else if (!cardNumber.success) {
            toast.error('No trades !')
        } else {
            toast.error('Input must be empty !')
        }
    }


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="deposit page">
                {
                    page < 4 &&
                    <Head nextCount={page} setPage={setPage} />
                }
                <div className="deposit__body body">
                {
                    page === 1 ?
                        <div>
                            <div className="body__titles">
                                <img className='img' src={depositImg1} alt="img"/>
                                <h2 className="title">{ t('deposit.page1.title') }</h2>
                            </div>
                            <form className='body__form form'>
                                <label>
                                    <span className='form__txt'>{ t('deposit.page1.label1.txt') }</span>
                                    <input
                                        className='form__inp'
                                        type="tel"
                                        placeholder={`Min ${ formatPrice(content.minDepositP1) } uzs`}
                                        defaultValue={ localStorage.getItem('amount') }
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </label>
                                <label>
                                    <span className='form__txt'>{ t('deposit.page1.label2.txt') }</span>
                                    <input
                                        className='form__inp'
                                        type="tel"
                                        placeholder={ t('deposit.page1.label2.inp') }
                                        defaultValue={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </label>
                                <button
                                    className='form__btn'
                                    onClick={goNextPage}
                                >
                                    { t('deposit.page1.next_btn') }
                                </button>
                            </form>
                        </div>
                        : page === 2 ? <Deposit2 amount={amount} setPage={setPage} cardNumber={cardNumber} />
                            : page === 3 ? <Deposit3 amount={amount} setAmount={setAmount} setPage={setPage} cardNumber={cardNumber} tradeId={cardNumber.data?.id} phoneNumber={phoneNumber} />
                                : <DepositWaiting />
                }
                </div>
            </div>
        </div>
    );
};

export default Deposit;
