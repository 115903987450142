import React from 'react';
import $api from "../../api";
import ContentLoader from 'react-content-loader'

const Carousel = ({ i }) => {

    const [img, setImg] = React.useState(null)

    React.useEffect(() => {
        if (i.attachmentId) {
            $api
                .get(`/api/robot/v1/attachment/get/${i.attachmentId}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setImg(base64)
                })
        }
    }, [i.attachmentId])

    const DoorDashFavorite = props => (
        <ContentLoader
            width={'100%'}
            height={'100%'}
            viewBox="0 0 500 200"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
        >
            <rect x="1" y="1" rx="10" ry="10" width="500" height="100%" />
        </ContentLoader>
    )

    return (
        img !== null ?
            <a className='link' href={i.url ? i.url : '#'} target={i.url ? '_blank' : ''}>
                <img className='img' src={`data:;base64,${img}`} alt='img'/>
            </a>
            : <DoorDashFavorite />
    );
};

export default Carousel;
