import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";

const RequireAuth = () => {

    const location = useLocation()

    const values = Object.keys(localStorage);

    return (
        values.includes('token') ? <Outlet />
            : <Navigate from='/' to='/landing' state={{from: location}} replace />
    );
};

export default RequireAuth;
