import './Menu.scss'
import React, {useEffect, useState} from 'react';
import nav6 from '../../assets/images/menu-nav1.svg'
import nav5 from '../../assets/images/menu-nav2.svg'
import nav4 from '../../assets/images/menu-nav3.svg'
import nav3 from '../../assets/images/menu-nav4.svg'
import nav2 from '../../assets/images/menu-nav5.svg'
import nav1 from '../../assets/images/menu-nav6.svg'
import nav0 from '../../assets/images/menu-nav0.svg'
import deposit1 from '../../assets/images/menu-deposit1.svg'
import deposit2 from '../../assets/images/menu-deposit2.svg'
import kuponGift from '../../assets/images/gift.svg'
import {Link, useNavigate} from "react-router-dom"
import $api from "../../api";
import {toast} from "react-hot-toast";
import {formatPrice} from '../../assets/scripts/globalScripts'
import {useTranslation} from "react-i18next";

const Menu = () => {


    const { t } = useTranslation()

    const nav = [
        {
            title: t('menu.head.nav.nav1.title'),
            link: '/menu',
            desc: t('menu.head.nav.nav1.desc'),
            icon: nav1
        },
        {
            title: t('menu.head.nav.nav2.title'),
            link: '/deposit',
            desc: t('menu.head.nav.nav2.desc'),
            icon: nav2
        },
        {
            title: t('menu.head.nav.nav3.title'),
            link: '/withdraw',
            desc: t('menu.head.nav.nav3.desc'),
            icon: nav3
        },
        {
            title: t('menu.head.nav.nav0.title'),
            link: '/bots/exchange',
            desc: t('menu.head.nav.nav0.desc'),
            icon: nav0
        },
        {
            title: t('menu.head.nav.nav4.title'),
            link: '/cards',
            desc: t('menu.head.nav.nav4.desc'),
            icon: nav4
        },
        {
            title: t('menu.head.nav.nav5.title'),
            link: '/history',
            desc: t('menu.head.nav.nav5.desc'),
            icon: nav5
        }
    ]

    const navigate = useNavigate()

    const [modal, setModal] = useState(false)

    const me = JSON.parse(localStorage.getItem('me'))


    const [scModal, setScModal] = useState(false)
    const [cupon, setCupon] = useState('')
    const [amount, setAmount] = useState(0)
    const useCupon = () => {
        $api
            .post('/api/robot/v1/coupon/use', null, {
                params: {
                    "code": cupon
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setAmount(res.data.data)
                setScModal(true)
                toast.success('Qoshildi !')
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    // get me 
    const [balance, setBalance] = useState([])
    useEffect(() => {
        $api    
            .get('/api/robot/v1/user/me', {
                headers: {
                    "Authorization": localStorage.getItem('token')
                }
            })
            .then(res => {
                setBalance(res.data)
                localStorage.setItem('me', JSON.stringify(res.data.data))
            })
    }, [])


    // get content to localStorage
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                localStorage.setItem('content', JSON.stringify(res.data.data))
            })
    }, [])


    // get img
    const [profileImg, setProfileImg] = useState(null)
    useEffect(() => {
        if (me?.file) {
            $api
                .get(`/api/robot/v1/attachment/get/${me.file.id}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [me])


    // notification messages
    const [messages, setMessages] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/notification/count', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMessages(res.data.data)
            })
    })


    // log out
    const logOut = () => {
        localStorage.removeItem('token')
        navigate('/sign-in')
    }


    return (
        <div className='container'>
            <div className="menu page">
                <div className="menu__head head">
                    <div className='head__notf'>
                        <Link className='link' to='/notf'>
                            <i className="fa-solid fa-bell icon"/>
                            <span className='count'>{ messages }</span>
                        </Link>
                    </div>
                    <div className="head__profile profile">
                        <div className="profile__wrapper row">
                            <div className={`profile__imgs ${profileImg !== null ? 'pro' : ''}`}>
                                {
                                    profileImg ?
                                        <img className='img' src={`data:;base64,${profileImg}`} alt="img"/>
                                        : <i className="fa-solid fa-user icon"/>
                                }
                            </div>
                            <div className='profile__titles'>
                                <h2 className="title">{ me?.name }</h2>
                                <span className='txt'>ID: { me?.id }</span>
                                <span className='txt'>{ t('menu.head.phone') }: { me?.phoneNumber }</span>
                                <Link className='edit-link' to='edit-profile'>{ t('menu.head.edit_profile') }</Link>
                            </div>
                        </div>
                        <div className="profile__balance">
                            <div style={{textAlign: 'center'}}>
                                <span className='price'>
                                    { formatPrice(balance.data?.balanceUZS.toFixed()) } uzs
                                </span>
                                <span className='txt'>{ t('menu.head.main_balance') }</span>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <span className='price'>
                                    { formatPrice(balance.data?.activeBalance.toFixed()) } uzs
                                </span>
                                <span className='txt'>{ t('menu.head.withdraw_balance') }</span>
                            </div>
                        </div>
                    </div>
                    <div className='head__links'>
                        <Link
                            className='row align-center'
                            style={{flexFlow: 'column'}}
                            to='/deposit'
                        >
                            <div className="imgs">
                                <img className='img' src={deposit1} alt="icon"/>
                            </div>
                            <span className='txt'>{ t('menu.head.deposit') }</span>
                        </Link>
                        <Link
                            className='row align-center'
                            style={{flexFlow: 'column'}}
                            to='/withdraw'
                        >
                            <div className="imgs">
                                <img className='img' src={deposit2} alt="icon"/>
                            </div>
                            <span className='txt'>{ t('menu.head.withdraw') }</span>
                        </Link>
                    </div>
                </div>
                <div className="menu__body body">
                    <ul className="body__list">
                        {
                            nav.map(i => (
                                <li className='body__item item' key={i.title}>
                                    <Link className='item__link row between align-center' to={i.link} onClick={(e) => e.target.innerText === t('menu.head.nav.nav1.title') && setModal(true)}>
                                        <div className='row align-center' style={{flexFlow: 'nowrap'}}>
                                            <div className="item__imgs">
                                                <img className='img' src={i.icon} alt="icon"/>
                                            </div>
                                            <div className='item__titles'>
                                                <span className='title'>{ i.title }</span>
                                                <p className='desc'>{ i.desc }</p>
                                            </div>
                                        </div>
                                        <i className="fa-solid fa-chevron-right icon"/>
                                    </Link>
                                </li>
                            ))
                        }
                        <li className='item__item item log-out row between align-center' onClick={logOut}>
                            <div className='row align-center' style={{flexFlow: 'nowrap'}}>
                                <div className="item__imgs">
                                    <img className='img' src={nav6} alt="icon"/>
                                </div>
                                <div className='item__titles'>
                                    <span className='title'>{ t('menu.head.nav.nav6.title') }</span>
                                    <p className='desc'>{ t('menu.head.nav.nav6.desc') }</p>
                                </div>
                            </div>
                            <i className="fa-solid fa-chevron-right icon"/>
                        </li>
                    </ul>
                </div>
                <>
                    {
                        modal &&
                        <div className='modal'>
                            <div className='modal__content content'>
                                {
                                    !scModal ?
                                        <div>
                                            <img className='img' src={kuponGift} alt="icon"/>
                                            <div className='content__inps'>
                                                <input
                                                    className='content__inp'
                                                    type="text"
                                                    placeholder='Code here'
                                                    onChange={(e) => setCupon(e.target.value)}
                                                />
                                                <div className='row' style={{gap: '10px', justifyContent: 'flex-end'}}>
                                                    <button className='content__btn gray' onClick={() => setModal(false)}>
                                                        { t('menu.head.cancel_btn') }
                                                    </button>
                                                    <button className='content__btn' onClick={useCupon}>
                                                        { t('menu.head.get_btn') }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='modal__last last'>
                                            <p className='last__desc'>
                                                <span>{ '+' + formatPrice(amount) }</span>
                                                <br/> uzs in you wallet !</p>
                                            <button
                                                className='last__btn'
                                                type='button'
                                                onClick={() => {
                                                    setScModal(false)
                                                    setModal(false)
                                                }}
                                            >
                                                OK
                                            </button>
                                        </div>
                                }
                            </div>
                            <div className='bg' onClick={() => {
                                setScModal(false)
                                setModal(false)
                            }}/>
                        </div>
                    }
                </>
            </div>
        </div>
    )
}

export default Menu;
