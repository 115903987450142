import React, {useEffect, useState} from 'react';
import Head from "../../../../components/UI/page-head/Head";
import payeer from '../../../../assets/images/payeer.png'
import $api from "../../../../api";
import {toast} from "react-hot-toast";
import ButtonLoader from 'react_button_loader';
import {formatPrice} from "../../../../assets/scripts/globalScripts";
import { useNavigate } from 'react-router-dom';

const WPayeer = () => {

    const navigate = useNavigate()

    const me = JSON.parse(localStorage.getItem('me'))
    const content = JSON.parse(localStorage.getItem('content'))


    // get cards
    const [cards, setCards] = useState([])
    const [cardNumber, setCardNumber] = useState('')
    useEffect(() => {
        $api
            .get('/api/robot/v1/card', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setCards(res.data)
                setCardNumber(res.data.data[0].number)
            })
    }, [])


    const [load, setLoad] = useState(false)
    const [amount, setAmount] = useState(0)
    const payW = () => {

        const item = {
            amount: amount,
            desc: me.name
        }
        $api
            .post('/api/robot/v1/payment/order-payeer', item, {
                params: { type: 'PAYEER' },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTimeout(() => {
                    setLoad(false)
                    const link = document.createElement('a')
                    link.href = res.data.message
                    link.setAttribute('target', '_blank')
                    document.body.appendChild(link)
                    link.click()
                }, 1500)
                setTimeout(() => navigate('/menu'), 1600)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
                setTimeout(() => setLoad(false), 1500)
            })
    }

    useEffect(() => {
        if (load) {
            payW()
        }
    }, [load, payW])

    return (
        <div className='container'>
            <div className="payeer">
                <Head />
                <div className="payeer__body body">
                    <div className="body__titles">
                        <h2 className="title">You chose Payeer method</h2>
                        <img className='img' src={payeer} alt="payeer"/>
                    </div>
                    <form className='body__form form'>
                        <label>
                            <div className='form__inps row between align-center'>
                                <input
                                    className='inp'
                                    type="number"
                                    placeholder={`Min: ${ formatPrice(content?.minWithdrawP1) } uzs`}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                                <span className="txt">UZS</span>
                            </div>
                            <span className='form__txt'>Enter the payment amount in uzs</span>
                        </label>
                        <label>
                            <div style={{margin: '0 4rem 2rem'}}>
                                <select className='form__inp' onChange={(e) => setCardNumber(e.target.value)}>
                                    {
                                        cards.success &&
                                        cards.data?.length > 0 ?
                                            cards.data.map(i => (
                                                <option value={i.number} key={i.id}>{ i.number }</option>
                                            ))
                                            : <option>No payeer accounts !</option>
                                    }
                                </select>
                            </div>
                        </label>
                        <ButtonLoader
                            className='form__btn'
                            isLoading={load}
                            onClick={() => setLoad(true)}
                        >
                            Confirm
                        </ButtonLoader>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default WPayeer