import '../Withdraw.scss'
import React, {useEffect, useState} from 'react';
import withdrawImg from '../../../../assets/images/withdraw.svg'
import $api from "../../../../api";
import {useNavigate} from "react-router-dom";
import WithdrawDone from "./WithdrawDone";
import toast from "react-hot-toast";
import {formatCardNumbers, formatPrice} from '../../../../assets/scripts/globalScripts'
import {useTranslation} from "react-i18next";


const Withdraw = () => {

    const navigate = useNavigate()

    const content = JSON.parse(localStorage.getItem('content'))

    const [next, setNext] = useState(false)


    // get cards
    const [cards, setCards] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/card', {
                params: { type: 'CARD' },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setCards(res.data)
                setCardNumber(res.data.data[0].number)
            })
    }, [])


    // submit withdraw
    const [amount, setAmount] = useState(0)
    const [cardNumber, setCardNumber] = useState('')
    const withdraw = (e) => {
        e.preventDefault()

        const item = {
            amount: amount,
            card: cardNumber
        }
        $api
            .post('/api/robot/v1/payment-order/withdraw-p1', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Success')
                setTimeout(() => {
                    setNext(true)
                }, 2000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const me = JSON.parse(localStorage.getItem('me'))


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="withdraw">
                {
                    !next ?
                        <>
                            <div className="withdraw__head head row between align-center">
                                <button className='head__btn' onClick={() => navigate(-1)}>
                                    <i className="fa-solid fa-arrow-left icon"/>
                                </button>
                                <h2 className='head__title'>{ t('withdraw.title') }</h2>
                                <span />
                            </div>
                            <div className="withdraw__body body">
                                <img className='body__img' src={withdrawImg} alt="img"/>
                                <form className="body__form form">
                                    <label>
                                        <div className="row between">
                                            <span className='txt'>{ t('withdraw.label1.txt1') }</span>
                                            <span className='txt'>{ t('withdraw.label1.txt2', {balance: formatPrice(me?.activeBalance?.toFixed())}) }</span>
                                        </div>
                                        <input
                                            className='form__inp'
                                            type="number"
                                            placeholder={`Min: ${ formatPrice(content?.minWithdrawP1) } uzs`}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        <span className='txt'>{ t('withdraw.label2') }</span>
                                        <div>
                                            <select className='form__inp' onChange={(e) => setCardNumber(e.target.value)}>
                                                {
                                                    cards.success &&
                                                    cards.data?.length > 0 ?
                                                        cards.data.map(i => (
                                                            <option value={i.number} key={i.id}>
                                                                { formatCardNumbers(i.number) + ' ' + i.bankName }
                                                            </option>
                                                        ))
                                                        : <option>No cards !</option>
                                                }
                                            </select>
                                        </div>
                                    </label>
                                    <button className='form__btn' onClick={withdraw}>{ t('withdraw.btn') }</button>
                                </form>
                            </div>
                        </>
                        : <WithdrawDone />
                }
            </div>
        </div>
    );
};

export default Withdraw;
