import './Cards.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../components/UI/page-head/Head";
import CardsItem from "./CardsItem";
import {toast} from "react-hot-toast";
import $api from "../../api";
import {useNavigate} from "react-router-dom";

const Cards = () => {

    const navigate = useNavigate()

    const [effect, setEffect] = useState(false)
    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/card', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
            })
    }, [effect, navigate])

    return (
        <div className='container'>
            <div className="card page">
                <Head plus={true} />
                <div className="card__body">
                    <ul className='card__list row'>
                        {
                            result.success ?
                            result.data.length > 0 ?
                                result.data.map(i => (
                                    <CardsItem i={i} setEffect={setEffect} key={i.id} />
                                )) : <p className='loading red'>No cards !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Cards;
