import React from 'react';

const MyInput = ({ type, value, placeholder, img, defaultValue }) => {

    return (
        <label className='relative-p'>
            <input
                className='sign__inp'
                type={type}
                placeholder={placeholder}
                onChange={(e) => value(e.target.value)}
                defaultValue={defaultValue}
                autoComplete={'new-password'}
            />
            <div
                className='sign__eye center-absolute'
            >
                <img src={img} alt="icon"/>
            </div>
        </label>
    );
};

export default MyInput;
