import React, {useEffect, useState} from 'react';
import tg from "../../assets/images/tg.svg";
import $api from "../../api";

const NetworkingLinks = ({ i }) => {

    // img
    const [netImg, setNetImg] = useState(null)
    useEffect(() => {
        if (i.attachmentId) {
            $api
                .get(`/api/robot/v1/attachment/get/${i.attachmentId}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setNetImg(base64)
                })
        }
    }, [i.attachmentId])


    return (
        <li className="content__item" key={i.id}>
            <a className='link row align-center' href={i.path} target="_blank">
                <div className='row align-center' style={{flexFlow: 'column'}}>
                    {
                        netImg ?
                            <img className='img' src={`data:;base64,${netImg}`} alt="img"/>
                            : <img className='img' src={tg} alt="img"/>
                    }
                    <span className='txt'>{ i.name }</span>
                </div>
                <p className='desc'>Join the group and share your thoughts</p>
            </a>
        </li>
    );
};

export default NetworkingLinks;
