import React from 'react';
import withdrawDone from '../../../../assets/images/withdraw-done.svg'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const WithdrawDone = () => {

    const { t } = useTranslation()

    return (
        <div className='done'>
            <div>
                <img className='img' src={withdrawDone} alt="img"/>
                <p className='desc'>
                    { t('withdraw.page2.desc') }
                </p>
                <Link className='btn' to='/'>{ t('withdraw.page2.btn') }</Link>
            </div>
        </div>
    )
}

export default WithdrawDone;
