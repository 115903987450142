import React, {useState} from 'react';
import $api from "../../api";
import {toast} from "react-hot-toast";
import {formatCardNumbers} from "../../assets/scripts/globalScripts";
import uzcard from "../../assets/images/uzcard.png";
import humo from "../../assets/images/humo.webp";
import payeer from "../../assets/images/payeer.png";
import cardSim from "../../assets/images/card.png";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CardsItem = ({ i, setEffect }) => {

    const [delModal, setDelModal] = useState(false)

    // delete card
    const deleteCard = () => {
        $api
            .delete(`/api/robot/v1/card/${i.id}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setDelModal(false)
                toast.success('Deleted !')
                setEffect(prev => !prev)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    const { t } = useTranslation()


    return (
        <li className='card__item item'>
            <div className='row between align-center' style={{marginBottom: '1.5rem'}}>
                <img className='item__img sim' src={cardSim} alt="img"/>
                {
                    i.number[0] == 8 ?
                        <img className='item__img' src={uzcard} alt="img"/>
                        : i.number[0]?.toLowerCase() === 'p' ?
                            <img className='item__img' src={payeer} alt="img"/>
                            : <img className='item__img' src={humo} alt="img"/>
                }
            </div>
            <div className='row between align-center'>
                <div className='item__titles'>
                    <span className='item__number'>{ formatCardNumbers(i.number) }</span>
                    <span className="item__bank">{ i.bankName }</span>
                </div>
                <div className="item__btns">
                    <Link className='item__btn' to={`add/:${i.id}`}>{ t('cards.edit') }</Link>
                    <button className='item__btn delete' onClick={() => setDelModal(true)}>{ t('cards.delete') }</button>
                </div>
            </div>
            {
                delModal &&
                <div className='modal'>
                    <div className={'modal__content content'}>
                        <span className={'content__title'}>Confirm</span>
                        <div className={'content__conf row center'}>
                            <button className={'btn confirm'} onClick={deleteCard}>
                                <i className="fa-solid fa-check icon"/>
                            </button>
                            <button className={'btn cancel'} onClick={() => setDelModal(false)}>
                                <i className="fa-solid fa-xmark icon"/>
                            </button>
                        </div>
                    </div>
                    <div className='bg' onClick={() => setDelModal(false)}/>
                </div>
            }
        </li>
    );
};

export default CardsItem;
