import React, {useEffect, useState} from 'react'
import $api from "../../../api";
import docs from '../../../assets/images/invest-docs.svg'
import {formatPrice} from '../../../assets/scripts/globalScripts'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const BotsIncomeItem = ({ i }) => {

    // get img
    const [profileImg, setProfileImg] = useState(null)
    useEffect(() => {
        if (i.robot.attachmentId) {
            $api
                .get(`/api/robot/v1/attachment/get/${i.robot?.attachmentId}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [i.robot?.attachmentId])

    const { t } = useTranslation()


    return (
        <li className='item'>
            <div className='item__wrapper row between'>
                <div>
                    <span className='item__txt'>{ t('plans.body.price_of_plan') }: { formatPrice(i.robot?.price?.toFixed()) } uzs</span>
                    <span className='item__txt'>{ t('plans.body.total_income') }: { formatPrice(i.robot?.dailyProfit?.toFixed() * i.robot.expiredDay?.toFixed()) } uzs</span>
                    <span className='item__txt'>{ t('plans.body.daily_income') }: { formatPrice(i.robot?.dailyProfit?.toFixed()) } uzs</span>
                    <span className='item__txt'>{ t('plans.my_plans_page.days_left') }: { i?.dueDate }</span>
                    {/*<span className='item__txt'>{ t('plans.my_plans_page.profit') }: { formatPrice(i.income?.toFixed()) } uzs</span>*/}
                </div>
                <div className='row between align-center' style={{flexFlow: 'column'}}>
                    <div className='row align-center' style={{flexFlow: 'column'}}>
                        {
                            profileImg !== null ?
                                <img className='item__img' src={`data:;base64,${profileImg}`} alt="icon"/>
                                : <img className='item__img img' src={docs} alt="icon"/>
                        }
                        <span className='item__img-txt'>{ i.robot.name }</span>
                    </div>
                    <div className='btns row' style={{flexFlow: 'column'}}>
                        <Link className='item__btn' to={`:${i.id}`}>{ t('plans.body.activate') }</Link>
                        <span>{ i.acceptedBot + '/' + i.robot.acceptCount }</span>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default BotsIncomeItem