import React, {useEffect, useState} from 'react';
import Head from "../../../components/UI/page-head/Head";
import $api from "../../../api";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import BotsIncomeItem from "./BotsIncomeItem";
import {formatPrice} from "../../../assets/scripts/globalScripts";

const InvestmentIncome = () => {

    const navigate = useNavigate()

    const [effect, setEffect] = useState([false])

    // my investments
    const [myPlans, setMyPlans] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/robot/my-bots', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMyPlans(res.data)
            })
    }, [navigate])

    // purchase data
    const [totalPurchase, setTotalPurchase] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/robot/bot-purchased', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTotalPurchase(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [effect, navigate])

    // get money
    const acceptPurchase = () => {
        $api
            .post('/api/robot/v1/robot/accept-all-my-purchased', null, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setEffect(prev => !prev)
                toast.success('Done !')
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="invest invest2 page">
                <Head />
                <div className="body">
                    <div className='body__top'>
                        <p className='txt'>
                            { t('plans.my_plans_page.total_plans_income') }: { totalPurchase.success ? formatPrice(totalPurchase.data?.totalBenefit?.toFixed()) : 0 } uzs
                        </p>
                    </div>
                    <ul className='body__list'>
                        {
                            myPlans.success ?
                                myPlans.data.length > 0 ?
                                    myPlans.data.map(i => (
                                        <BotsIncomeItem i={i} key={i.id} />
                                    ))
                                    : <p className='loading red'>No plans !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InvestmentIncome;
