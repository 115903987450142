import './Notification.scss'
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import $api from "../../api";
import toast from "react-hot-toast";
import NotificationItem from "./NotificationItem";

const Notfication = () => {

    const navigate = useNavigate()

    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/notification/my-notification', {
                params: {
                    page: 0,
                    size: 20
                },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate])

    return (
        <div className='container'>
            <div className="notf page">
                <div className="notf__head head">
                    <button className='head__btn' onClick={() => navigate(-1)}>
                        <i className="fa-solid fa-chevron-left icon"/>
                    </button>
                    <span className='head__title'>Notification</span>
                </div>
                <div className="notf__body body">
                    <ul className='body__list'>
                        {
                            result.success ?
                                result.data.length > 0 ?
                                    result.data.map(i => (
                                        <NotificationItem i={i} key={i.id} />
                                    )) : <p className='loading red'>No data !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Notfication;
