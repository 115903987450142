import './Cards.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../components/UI/page-head/Head";
import {toast} from "react-hot-toast";
import $api from "../../api";
import {useNavigate, useParams} from "react-router-dom";
import card from '../../assets/images/card.png'
import {useTranslation} from "react-i18next";

const AddCard = () => {

    const navigate = useNavigate()

    const [cardType, setCardType] = useState(true)
    const [cardNumber, setCardNumber] = useState('P')
    const [bankName, setBankName] = useState('')

    const addCard = (e) => {
        e.preventDefault()

        const item = {
            number: cardNumber,
            name: 'nmadr',
            bankName: bankName,
            payType: cardType ? 'PAYEER' : 'CARD'
        }
        $api
            .post('/api/robot/v1/card', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Qoshildi !')
                setTimeout(() => {
                    navigate('/cards')
                }, 1000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    const { id } = useParams()

    const [result, setResult] = useState([])
    useEffect(() => {
        if (id !== undefined) {
            $api
            .get(`/api/robot/v1/card/${id?.slice(1)}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data.data)
                setCardNumber(res.data.data?.number)
                setBankName(res.data.data?.bankName)
            })
        }
    }, [id])

    const editCard = (e) => {
        e.preventDefault()

        const item = {
            name: 'nmadr',
            number: cardNumber,
            bankName: bankName,
            payType: result?.payType
        }
        $api
            .put(`/api/robot/v1/card/${id.slice(1)}`, item, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Successful edited !')
                setTimeout(() => {
                    navigate('/cards')
                }, 1000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="card page">
                <Head />
                <div className="card__body">
                    <div className='img add-imgs'>
                        <img className='add-img' src={card} alt="card"/>
                    </div>
                    {
                        id === undefined ?
                            <form className='card__form form'>
                                <label>
                                    <input
                                        className='form__inp'
                                        type="tel"
                                        placeholder={t('cards.label1')}
                                        value={cardNumber}
                                        onChange={(e) => setCardNumber(e.target.value)}
                                    />
                                </label>
                                <label>
                                    <input
                                        className='form__inp'
                                        type="text"
                                        placeholder={t('cards.label2')}
                                        onChange={(e) => setBankName(e.target.value)}
                                    />
                                </label>
                                <label className='radio-label row align-center'>
                                    <span className='txt'>Payeer</span>
                                    <input
                                        className='inp'
                                        type="radio"
                                        name='radio'
                                        defaultChecked={true} 
                                        onChange={() => {
                                            setCardType(true)
                                            setCardNumber('P')
                                        }}
                                    />
                                </label>
                                <label className='radio-label row align-center'>
                                    <span className='txt'>Card</span>
                                    <input
                                        className='inp'
                                        type="radio"
                                        name='radio'
                                        onChange={() => {
                                            setCardType(false)
                                            setCardNumber('')
                                        }}
                                    />
                                </label>
                                <button className='form__btn' onClick={addCard}>Add card</button>
                            </form>
                            :
                            <form className='card__form form'>
                                <label>
                                    <input
                                        className='form__inp'
                                        type="tel"
                                        placeholder='Enter card number'
                                        defaultValue={result?.number}
                                        onChange={(e) => setCardNumber(e.target.value)}
                                    />
                                </label>
                                <label>
                                    <input
                                        className='form__inp'
                                        type="text"
                                        placeholder='Name of card'
                                        defaultValue={result?.bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                    />
                                </label>
                                <button className='form__btn' onClick={editCard}>{ t('cards.btn') }</button>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddCard;
