import React, {useState} from 'react';

const NotificationItem = ({ i }) => {

    const [modal, setModal] = useState(false)

    return (
        <li className="body__item item">
            <div className='item__touch row between' onClick={() => setModal(true)}>
                <div>
                    <span className='item__title'>{ i.title }</span>
                    <p className='item__desc'>{ i.text }</p>
                </div>
                <span className='item__time'>{ new Date(i.date).toLocaleDateString() }</span>
            </div>
            <>
                {
                    modal &&
                    <div className='modal'>
                        <div className="content">
                            <div className='content__titles'>
                                <span className='content__title'>{ i.title }</span>
                                <span className='content__time'>{ new Date(i.date).toLocaleString() }</span>
                            </div>
                            <p className='content__desc'>{ i.text }</p>
                            <button className='content__btn' onClick={() => setModal(false)}>Dissmiss</button>
                        </div>
                        <div className="bg" onClick={() => setModal(false)}/>
                    </div>
                }
            </>
        </li>
    );
};

export default NotificationItem;
