import React, {useEffect, useState} from 'react';
import Head from "../../../components/UI/page-head/Head";
import {Link, useNavigate, useParams} from "react-router-dom";
import img from '../../../assets/images/bot-img.svg'
import $api from "../../../api";
import {formatPrice} from "../../../assets/scripts/globalScripts";
import {toast} from "react-hot-toast";
import {Wrapper, Wrapper2} from "./BotsItem";
import Countdown from 'react-countdown';

const BotPage = () => {
    
    const { id } = useParams()

    const navigate = useNavigate()


    // get current bot
    const [effect, setEffect] = useState(false)
    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/robot/${id.slice(1)}`, {
                headers: {
                    "Authorization": localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
    }, [id, effect])


    // get timer
    const [time, setTime] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/robot/access-time/${id.slice(1)}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTime(res.data)
            })
    }, [effect])
    
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload()
            return (
                <span className="txt">Launch bot</span>
            )
        } else {
            return (
                <span className='txt'>
                    {minutes}:{seconds}
                </span>
            )
        }
    }


    // get me 
    const [me, setMe] = useState([])
    useEffect(() => {
        $api    
            .get('/api/robot/v1/user/me', {
                headers: {
                    "Authorization": localStorage.getItem('token')
                }
            })
            .then(res => {
                setMe(res.data)
            })
    }, [effect])


    // get another bots
    const [anotherBots, setAnotherBots] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/robot/my-bots`, {
                params: { id: id.slice(1) },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setAnotherBots(res.data)
            })
            .catch(err => {
                if (err?.response?.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [])


    // run bot
    const runBot = () => {
        $api
            .post(`/api/robot/v1/robot/accept-bot-purchased?userRobotId=${id.slice(1)}`, null, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                setEffect(prev => !prev)
                setTimeout(() => setEffect(prev => !prev), 1000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    return (
        <div className='container'>
            <div className="invest bot page">
                <div className="bot__head head">
                    <Head />
                    <div className='head__titles row align-center' style={{gap: '1rem'}}>
                        <div className='row align-center'>
                            <span className='money'>{ formatPrice(me.data?.activeBalance.toFixed()) } uzs</span>
                            <i className="fa-solid fa-wallet icon"/>
                        </div>
                        <Link className='info' to='/overview'>
                            <i className="fa-solid fa-circle-info icon"/>
                        </Link>
                    </div>
                </div>
                <div className="bot_body body">
                    <div className="body__titles">
                        <h2 className='title'>{ result.success ? result.data?.robot.name : '...' }</h2>
                        <img className='img' src={img} alt="img"/>
                        <button className='btn' onClick={runBot}>
                            {
                                time.success ?
                                    time.data?.access ?
                                        <span className='txt'>Launch bot</span>
                                        :    <Countdown date={Date.now() + time.data?.minute} renderer={renderer} />
                                    : <span className='txt'>. . .</span>
                            }
                            <i className="fa-solid fa-robot icon"/>
                        </button>
                        <div className='descs'>
                            <span className='green-txt'>{ result.data?.acceptedBot + '/' + result.data?.robot.acceptCount }</span>
                            <p className='desc'>You have { result.data?.robot.acceptCount } attempts left to use the bot</p>
                        </div>
                    </div>
                    <div className="body__another">
                        <span className='title'>Another bots</span>
                        <ul className="body__list">
                            {
                                anotherBots.success ?
                                    anotherBots.data.length > 0 ?
                                        anotherBots.data.map(i => (
                                            <li className='item' key={i.id}>
                                                <Wrapper2 i={i} />
                                            </li>
                                        ))
                                        : <p className='loading red'>No plans !</p>
                                    : <p className='loading'>Loading . . .</p>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BotPage;
