import React from 'react';
import depositWait from '../../../../assets/images/deposit-wait.svg'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const DepositWaiting = () => {

    const { t } = useTranslation()

    return (
        <div className='wait'>
            <div>
                <img className='img' src={depositWait} alt="img"/>
                <div style={{marginBottom: '2rem'}}>
                    <p className='desc'>{ t('deposit.page4.desc1') }</p>
                    <span className='desc'>{ t('deposit.page4.desc2') }</span>
                </div>
                <Link className='btn' to='/'>{ t('deposit.page4.btn') }</Link>
            </div>
        </div>
    );
};

export default DepositWaiting;
