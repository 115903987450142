import '../Sign.scss'
import React, {useState} from 'react';
import MyInput from "../UI/MyInput";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import $api from "../../../api";
import user from '../../../assets/images/sign-user.svg'
import phone from '../../../assets/images/sign-phone.svg'
import lock from '../../../assets/images/sign-lock.svg'
import scrip from '../../../assets/images/sign-scrip.svg'
import profileIcon from '../../../assets/images/sign-profile.svg'

const SignUp = () => {

    const refCode = useParams()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')
    const [referralCode, setReferralCode] = useState('')
    const [checked, setChecked] = useState(false)

    const signUp = (e) => {
        e.preventDefault()

        const item = {
            name: name,
            phoneNumber: number,
            password: password,
            referralCode: referralCode !== '' ? referralCode : refCode ? refCode.id : referralCode,
            isOne: true
        }
        if (checked) {
            $api
                .post('/api/robot/v1/auth/sign-up',
                    JSON.stringify(item), {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                .then(res => {
                    const accessToken = res.data.data.accessToken
                    localStorage.setItem('token', `Bearer ${accessToken}`)
                    localStorage.setItem('success', 'true')
                    navigate('/')
                })
                .catch(err => {
                    if (!err?.response) {
                        toast.error('No Server Response')
                    } else {
                        err.response.data.errors.map(i => (
                            toast.error(i.errorMsg)
                        ))
                    }
                })
        } else {
            toast.error('Rozilikni bildiring !')
        }
    }


    return (
        <div className='sign'>
            <div className="container">
                <div className="sign__inner">
                    <div className="sign__titles" style={{marginBottom: '1rem'}}>
                        <img className='sign__icon' src={profileIcon} alt="icon"/>
                        <h2 className="sign__title">Register</h2>
                    </div>
                    <form className='sign__form'>
                        <MyInput
                            type='text'
                            placeholder='Full name'
                            value={setName}
                            img={user}
                        />
                        <MyInput
                            type='tel'
                            placeholder='Phone number'
                            value={setNumber}
                            img={phone}
                        />
                        <MyInput
                            type='password'
                            placeholder='Strong Password'
                            value={setPassword}
                            img={lock}
                        />
                        {
                            refCode.id === undefined &&
                            <MyInput
                                type='tel'
                                placeholder='Referral code'
                                value={setReferralCode}
                                img={scrip}
                            />
                        }
                        <label className='checkbox row align-center relative-p'>
                            <input className='checkbox__inp' type="checkbox" onClick={() => setChecked(!checked)}/>
                            {/*<span className='checkmark'/>*/}
                            <span className='checkbox__txt'>By checking the box you agree to our <Link to={'/overview'}>Terms</Link></span>
                        </label>
                        <button className='sign__btn' onClick={signUp}>
                            <span>Next</span>
                            <i className="fa-solid fa-chevron-right"/>
                        </button>
                    </form>
                    <p className='sign__desc'>Already a member? <Link className='sign__desc-link' to='/sign-in'>Log In</Link></p>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
