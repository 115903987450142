import '../Investment.scss'
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import img from '../../../assets/images/gift-money.svg'
import exchangeImg from '../../../assets/images/exchange.svg'
import {formatPrice} from "../../../assets/scripts/globalScripts";
import $api from '../../../api';
import { toast } from 'react-hot-toast';

const BotExchange = () => {

    const navigate = useNavigate()

    const me = JSON.parse(localStorage.getItem('me'))
    const content = JSON.parse(localStorage.getItem('content'))

    const [modal, setModal] = useState(false)

    const [amount, setAmount] = useState(0)
    const exchange = (e) => {
        e.preventDefault()

        $api
            .post('/api/robot/v1/payment-order/transfer', null, {
                params: { amount: amount },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Success !')
                setTimeout(() => navigate('/menu'), 2000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }


    return (
        <div className='container'>
            <div className="exchange page">
                <div className="exchange__head head row between align-center">
                    <button className='head__btn' onClick={() => navigate(-1)}>
                        <i className="fa-solid fa-arrow-left icon"/>
                    </button>
                    <h2 className="head__title">Exchange money</h2>
                    <button className='head__btn info' onClick={() => setModal(true)}>
                        <i className="fa-solid fa-circle-info icon"/>
                    </button>
                </div>
                <div className="exchange__body body">
                    <div className="body__titles">
                        <img className='img' src={img} alt="img"/>
                        <span className='procent'>+{ content?.withdrawBonus }%</span>
                        <span className='txt'>Per exchange</span>
                    </div>
                    <form className='body__form form'>
                        <label>
                            <span className='form__txt'>Enter the exchange amount in uzs</span>
                            <div className='relative-p'>
                                <input
                                    className='form__inp'
                                    type="number"
                                    placeholder='Money...'
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                                <span className='uzs'>UZS</span>
                            </div>
                        </label>
                        <img className='img' src={exchangeImg} alt="icon" />
                        <div style={{marginBottom: '1.5rem'}}>
                            <span className='form__txt'>You get for main balance</span>
                            <div className='form__inp row between align-center' style={{paddingRight: '1rem'}}>
                                <span className='balance'>
                                    { formatPrice(
                                        (
                                            amount?.length ?
                                                (me.balanceUZS + +amount) + ((me.balanceUZS + +amount) * (content?.withdrawBonus / 100))
                                                : me.balanceUZS + +amount
                                        ).toFixed()
                                    ) } uzs
                                </span>
                                <span className='txt'>Balance</span>
                            </div>
                        </div>
                        <button className='form__btn' onClick={exchange}>Exchange</button>
                    </form>
                </div>
                {(
                    modal &&
                    <div className='modal'>
                        <div className="content">
                            <span className='content__title'>About exchange</span>
                            <p className='content__desc'>
                                To exchange your money, you have to transfer the income from robots to your main account. The minimum exchange is 10,000 uzs. You can exchange as many coins as you want every day and there is no limit. 20% bonus money will be added to you at the time of exchange and this bonus amount may change rapidly
                            </p>
                            <button className='content__btn' onClick={() => setModal(false)}>Understand</button>
                        </div>
                        <div className="bg" onClick={() => setModal(false)}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BotExchange;
