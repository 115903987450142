import './404.scss'
import React from 'react';
import {Link} from "react-router-dom";
import img from '../../../assets/images/error-img.svg'

const Page404 = () => {
    return (
        <div className='container'>
            <div className="error page">
                <div>
                    <img className='error__img' src={img} alt="img"/>
                    <p className='error__desc'>Oh No! Something went wrong</p>
                    <Link className='error__link' to='/'>Go to home page</Link>
                </div>
            </div>
        </div>
    );
};

export default Page404;
