import React, {useState} from 'react';
import deposit3 from '../../../../assets/images/deposit3.svg'
import $api from "../../../../api";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

const Deposit3 = ({ amount, setPage, tradeId, phoneNumber }) => {

    const [cardNumber, setCardNumber] = useState('')
    const [bankName, setBankName] = useState('')
    const [file, setFile] = useState(null)
    const confirmPayment = (e) => {
        e.preventDefault()

        const item = {
            amount: amount,
            cardNumber: cardNumber,
            bankName: bankName,
            phoneNumber: phoneNumber,
            attachmentId: file ? file.id : null,
            tradeId: tradeId
        }
        $api
            .post('/api/robot/v1/trade-order/deposit-p1', JSON.stringify(item), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Success !')
                setPage(prev => prev + 1)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    // send file
    const sendFile = (files) => {
        const toastId = toast.loading('Loading . . .')

        const file = files[0]
        const formData = new FormData()
        formData.append('file', file)
        $api
            .post('/api/robot/v1/attachment/upload', formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    "Authorization": localStorage.getItem('token')
                }
            })
            .then(function (res) {
                setFile(res.data.data[0])

                toast.success('Successful loaded !', {
                    id: toastId
                })
                setTimeout(() => toast.dismiss(toastId), 2000)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))

                toast.error('File is not loaded !', {
                    id: toastId
                })
                setTimeout(() => toast.dismiss(toastId), 2000)
            })
    }


    const { t } = useTranslation()


    return (
        <div className='deposit3'>
            <img className='img' src={deposit3} alt="img"/>
            <form className='form'>
                <label>
                    <span className='form__txt'>{ t('deposit.page3.label1') }</span>
                    <input
                        className='form__inp'
                        type="number"
                        placeholder='Eg: 0000 0000 0000 0000'
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                </label>
                <label>
                    <span className='form__txt'>{ t('deposit.page3.label2') }</span>
                    <input
                        className='form__inp'
                        type="text"
                        placeholder='Bank name'
                        onChange={(e) => setBankName(e.target.value)}
                    />
                </label>
                <label>
                    <span className='form__txt'>{ t('deposit.page3.label3') }</span>
                    <input
                        className='form__inp d-none'
                        type="file"
                        onChange={(e) => sendFile(e.target.files)}
                    />
                    <div className='form__inp file'>
                        {
                            file !== null ?
                                <span className='txt2'>{ file.name }</span>
                                : <span className='txt'>Choose file</span>
                        }
                    </div>
                </label>
                <p className='form__desc'>
                    { t('deposit.page3.desc') }
                </p>
                <button className='form__btn' onClick={confirmPayment}>{ t('deposit.page3.confirm_btn') }</button>
            </form>
        </div>
    );
};

export default Deposit3;
