import React, {useEffect, useState} from 'react';
import axios from "axios";
import {formatPrice} from "../../assets/scripts/globalScripts";

const Crypto = () => {

    const [result, setResult] = useState([])
    const [effect, setEffect] = useState(false)
    setTimeout(() => setEffect(!effect), 60000)
    useEffect(() => {
        axios
            .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=gecko_desc&per_page=10&page=1&sparkline=false&price_change_percentage=24h')
            .then(res => {
                setResult(res.data)
            })
    }, [effect])

    return (
        <ul className='crypto'>
            {
                result?.length ?
                    result.map(i => (
                        <li className='crypto__item item between align-center' key={i.id}>
                            <div className='item__imgs row align-center'>
                                <img className='item__img' src={i.image} alt="coin"/>
                                <div className='item__titles'>
                                    <span className='item__title'>{ i.symbol }</span>
                                    <span className='item__name'>{ i.name }</span>
                                </div>
                            </div>
                            <span
                                className={`item__precent ${ i.market_cap_change_percentage_24h < 0 ? 'red' : 'green' }`}
                            >
                                { i.market_cap_change_percentage_24h >= 0 && '+' }
                                { formatPrice(i.market_cap_change_percentage_24h) }
                            </span>
                            <span className='item__price'>{ formatPrice(i.current_price) } $</span>
                        </li>
                    ))
                    : <p className='loading'>Loading . . .</p>
            }
        </ul>
    );
};

export default Crypto;
