import '../Investment.scss'
import React, {useEffect, useState} from 'react';
import titleIcon from '../../../assets/images/invest-title.svg'
import wrapperIcon from '../../../assets/images/invest-wrapper-img.svg'
import {Link, useNavigate} from "react-router-dom";
import $api from "../../../api";
import {toast} from "react-hot-toast";
import {formatPrice} from "../../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";
import BotsItem from "./BotsItem";


const Bots = () => {

    const navigate = useNavigate()

    const [effect, setEffect] = useState(false)
    const [result, setResult] = useState([])

    useEffect(() => {
        $api
            .get('/api/robot/v1/robot/bots', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [effect, navigate])


    //get my bots
    const [myBots, setMyBots] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/robot/my-bots', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMyBots(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [effect, navigate])


    // purchase data
    const [totalBenefits, setTotalBenefits] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/robot/bot-purchased', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setTotalBenefits(res.data)
            })
    }, [effect])


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="invest page">
                <div className="invest__head head">
                    <div className="head__titles row align-center">
                        <h2 className='head__title'>{ t('plans.title') }</h2>
                        <img className='img' src={titleIcon} alt="icon"/>
                    </div>
                    <div className="head__wrapper">
                        <div className='head__diver'>
                            <img className='img' src={wrapperIcon} alt="icon"/>
                            <span className='txt'>{ t('plans.head.number_of_invest_plans') }: <span className="font">{ myBots.data?.length ? myBots.data?.length : 0 }</span></span>
                            <span className='txt-mini'>{ t('plans.head.current_income') }: <span className='font'>{ totalBenefits.success ? formatPrice(totalBenefits.data?.totalBenefit?.toFixed()) : 0 } uzs</span></span>
                        </div>
                        <div className='head__inner row between' style={{flexFlow: "column"}}>
                            <div>
                                <div className='txts row'>
                                    <span className='txt'>{ t('plans.head.total_benefit') }: </span>
                                    <span className='txt txt2 font'>{ totalBenefits.success ? formatPrice(totalBenefits.data?.totalBenefit?.toFixed()) : 0 } uzs</span>
                                </div>
                                <div className='txts row'>
                                    <span className='txt'>{ t('plans.head.today') }: </span>
                                    <span className='txt txt2 font'>{ totalBenefits.success ? formatPrice(totalBenefits.data?.todayBenefit?.toFixed()) : 0 } uzs</span>
                                </div>
                                <div className='txts row'>
                                    <span className='txt'>{ t('plans.head.last_week') }: </span>
                                    <span className='txt txt2 font'>{ totalBenefits.success ? formatPrice(totalBenefits.data?.lastWeekBenefit?.toFixed()) : 0 } uzs</span>
                                </div>
                            </div>
                            <Link className='btn' to='income'>{ t('plans.head.get_money') }</Link>
                        </div>
                    </div>
                </div>
                <div className="invest__body body">
                    <ul className='body__list'>
                        {
                            result.success ?
                                result.data.length > 0 ?
                                    result.data.map(i => (
                                        <BotsItem i={i} setEffect={setEffect} key={i.id} />
                                    ))
                                    : <p className='loading red'>No plans !</p>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Bots;
