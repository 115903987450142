import './Deposit.scss'
import React, {useEffect, useState} from 'react';
import Head from "../../../components/UI/page-head/Head";
import payeer from '../../../assets/images/payeer.png'
import card from '../../../assets/images/humo-uzcard.jpg'
import {useNavigate} from "react-router-dom";
import $api from "../../../api";
import toast from "react-hot-toast";

const Choose = ({ deposit }) => {

    const navigate = useNavigate()

    const [active, setActive] = useState(0)

    // get access
    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/payment/accesses?all=false', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                toast.error(i.errorMsg)
                ))
            })
    }, [])

    return (
        <div className='container'>
            <div className="choose">
                <Head />
                <div className="choose__body body">
                    <h2 className="body__title">Select { deposit ? 'deposit' : 'withdraw' } method</h2>
                    <div style={{height: '122px'}}>
                        {
                            result.success ?
                                <div className='body__payment'>
                                    {
                                        result.data.map(i => (
                                            i.access &&
                                                <div
                                                    className={`imgs`}
                                                    onClick={() => navigate(i.payType === 'PAYEER' ? 'payeer' : 'card')}
                                                >
                                                    <img
                                                        className='img payeer'
                                                        src={i.payType === 'PAYEER' ? payeer : card}
                                                        alt="payeer"
                                                    />
                                                </div>
                                        ))
                                    }
                                </div>
                                : <p className='loading'>Loading . . .</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Choose;
