import React, {useEffect, useState} from 'react';
import Head from "../../components/UI/page-head/Head";
import success from '../../assets/images/success.svg'
import pending from '../../assets/images/deposit-wait.svg'
import reject from '../../assets/images/reject.svg'
import {useParams} from "react-router-dom";
import $api from "../../api";
import toast from "react-hot-toast";
import {formatPrice} from "../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";

const HistoryItem = () => {

    const params = useParams()

    const me = JSON.parse(localStorage.getItem('me'))

    const [result, setResult] = useState([])
    useEffect(() => {
        $api
            .get(`/api/robot/v1/payment-order/my-history/${params.id.slice(1)}?type=${params.type.slice(1)}&payType=${params.payType.slice(1)}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setResult(res.data)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [params.id, params.payType, params.type])


    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="history inner page">
                <Head />
                <div className="history__body body">
                    <div className="body__titles">
                        {
                            result.data?.status === 'REJECT' ? <img className='img' src={reject} alt="img"/>
                                : result.data?.status === 'PAYED' || result.data?.status === 'ACCEPT' ? <img className='img' src={success} alt="img"/>
                                    : <img className='img' src={pending} alt="img"/>
                        }
                        <h2 className="title">
                            { result.data?.income ? t('history.deposit') : result.data?.income === false ? t('history.withdraw') : '. . .' } { result.data?.status === 'REJECT' ? t('history.reject') : result.data?.status === 'PAYED' || result.data?.status === 'ACCEPT' ? t('history.success') : result.data?.status === 'PENDING' ? t('history.pending') : '. . . ' }
                        </h2>
                        <span className='amount'>
                            { result.data?.income ? '+' : '-' }{ result.success ? formatPrice(result.data?.amount) : '--:--' } <span>uzs</span>
                        </span>
                    </div>
                    <div className='body__content content'>
                        <div className='content__item row between'>
                            <span className='txt'>ID</span>
                            <span className='txt txt2'>{ result.data?.id }</span>
                        </div>
                        <div className='content__item row between'>
                            <span className='txt'>{ t('history.payment') } <br/> { t('history.time') }</span>
                            <span className='txt txt2'>{ new Date(result.data?.date).toLocaleString() }</span>
                        </div>
                        <div className='content__item row between'>
                            <span className='txt'>{ t('history.payment') } <br/> { t('history.method') }</span>
                            <span className='txt txt2'>{ result.data?.payType !== null ? result.data?.payType : 'unknown' }</span>
                        </div>
                        <div className='content__item row between border pb'>
                            <span className='txt'>{ t('history.account') }</span>
                            <span className='txt txt2 account'>{ me?.name }</span>
                        </div>
                        <div className='content__item row between pt'>
                            <span className='txt'>{ t('history.amount') }</span>
                            <span className='txt txt2'>{ formatPrice(result.data?.amount) } uzs</span>
                        </div>
                        {/*<div className='content__item row between'>*/}
                        {/*    <span className='txt'>{ t('history.free') }</span>*/}
                        {/*    <span className='txt txt2'>{ '{null}' }</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryItem;
