import './Home.scss'
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import $api from "../../api";
import langImg from '../../assets/images/lang.svg'
import bellImg from '../../assets/images/home-bell.svg'
import link1 from '../../assets/images/link1.svg'
import link2 from '../../assets/images/link2.svg'
import link3 from '../../assets/images/link3.svg'
import link4 from '../../assets/images/link4.svg'
import link5 from '../../assets/images/link5.svg'
import link6 from '../../assets/images/link6.svg'
import person from '../../assets/images/home-person.svg'
import user from '../../assets/images/home-network-user.svg'
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
import {formatPrice} from "../../assets/scripts/globalScripts";
import Crypto from "./Crypto";
import NetworkingLinks from "./NetworkingLinks";
import i18next from 'i18next';
import Carousel from "./Carousel";

const Home = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()


    // social medias
    const [modal, setModal] = useState(false)
    const [links, setLinks] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/networking', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setLinks(res.data)
                if (localStorage.getItem('success') === 'true') {
                    toast.success('Successful logged')
                    localStorage.setItem('success', 'false')
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate])


    // notification messages
    const [messages, setMessages] = useState(0)
    useEffect(() => {
        $api
            .get('/api/robot/v1/notification/count', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMessages(res.data.data)
            })
    }, [])


    // get me to localStorage
    const me = JSON.parse(localStorage.getItem('me'))
    useEffect(() => {
        $api
            .get('/api/robot/v1/user/me', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                localStorage.setItem('me', JSON.stringify(res.data.data))
                localStorage.setItem('amount', '100000')
            })
    }, [])


    // get content to localStorage
    useEffect(() => {
        $api
            .get('/api/robot/v1/content', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                localStorage.setItem('content', JSON.stringify(res.data.data))
            })
    }, [])


    // carousel
    const [carousel, setCarousel] = useState([])
    useEffect(() => {
        $api
            .get('/api/robot/v1/carousel/find-all', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setCarousel(res.data)
            })
    }, [])


    // get img
    const [profileImg, setProfileImg] = useState(null)
    useEffect(() => {
        if (me?.file) {
            $api
                .get(`/api/robot/v1/attachment/get/${me.file.id}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [me])


    const linksArr = [
        {
            title: t("home.nav.deposit"),
            link: '/deposit',
            img: link1
        },
        {
            title: t("home.nav.plans"),
            link: '/bots',
            img: link2
        },
        {
            title: t("home.nav.referral"),
            link: '/referral',
            img: link3
        },
        {
            title: t("home.nav.challenge"),
            link: '/awards',
            img: link4
        },
        {
            title: t("home.nav.history"),
            link: '/history',
            img: link5
        },
        {
            title: t("home.nav.settings"),
            link: '/menu',
            img: link6
        },
    ]

    const [lang, setLang] = useState(false)


    return (
        <div className='container'>
            <div className='home page'>
                <div className="home__head head row between align-center">
                    <div className='head__user row align-center'>
                        <div className={`icons ${profileImg ? '' : 'border'}`}>
                            {
                                profileImg ?
                                    <img className='img' src={`data:;base64,${profileImg}`} alt="img"/>
                                    : <i className="fa-solid fa-user icon"/>
                            }
                        </div>
                        <div className='txts'>
                            <span className='txt'>{ t("home.hi") }</span>
                            <span className='name'>{ me?.name }</span>
                        </div>
                    </div>
                    <div className="row align-center" style={{gap: "1rem"}}>
                        <div className='head__lang'>
                            <button onClick={() => setLang(!lang)}>
                                <img className='img' src={langImg} alt="icon"/>
                            </button>
                            <div className={`dropdown row ${lang ? 'active' : ''}`}>
                                <button className='btn' onClick={() => {
                                    i18next.changeLanguage('en')
                                    setLang(false)
                                }}>en</button>
                                <button className='btn' onClick={() => {
                                    i18next.changeLanguage('ru')
                                    setLang(false)
                                }}>ru</button>
                                <button className='btn' onClick={() => {
                                    i18next.changeLanguage('uz')
                                    setLang(false)
                                }}>uz</button>
                            </div>
                        </div>
                        <Link className='head__notf' to='/notf'>
                            <img className='img' src={bellImg} alt="icon"/>
                            <span className='count'>{ messages }</span>
                        </Link>
                    </div>
                </div>
                <div className="home__body body">
                    <div className="home__slider slider">
                        <div className="slider__content">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    carousel.success ?
                                        carousel.data.map(i => (
                                            <SwiperSlide key={i.id}>
                                                <Carousel i={i} />
                                            </SwiperSlide>
                                        ))
                                        : <p className='loading'>Loading . . .</p>
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className="body__balance row between">
                        <div>
                            <div>
                                <span className='txt'>{ t("home.main_balance") }</span>
                                <span className='balance'>{ formatPrice(me?.balanceUZS?.toFixed()) } uzs</span>
                            </div>
                            <Link className='link' to='/deposit'>{ t("home.deposit_now") }</Link>
                        </div>
                        <img className='img' src={person} alt="img"/>
                    </div>
                    <ul className="body__links">
                        {
                            linksArr.map(i => (
                                <li className='body__item' key={i.title}>
                                    <Link className='link' to={i.link}>
                                        <div className="imgs">
                                            <img className='img' src={i.img} alt="img"/>
                                        </div>
                                        <span className='txt'>{ i.title }</span>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                    <Crypto />
                    <div className='body__network'>
                        <div className='icons' onClick={() => setModal(true)}>
                            <img className='icon' src={user} alt="icon"/>
                        </div>
                        {
                            modal &&
                            <div className="modal">
                                <div className="content">
                                    <ul className='content__list'>
                                        {
                                            links.success ?
                                                links.data.length > 0 ?
                                                    links.data.map(i => (
                                                        <NetworkingLinks i={i} key={i.id} />
                                                    )) : <p className='loading red'>No cards !</p>
                                                : <p className='loading'>Loading . . .</p>
                                        }
                                    </ul>
                                    <button className='content__btn' onClick={() => setModal(false)}>Close</button>
                                </div>
                                <div className="bg" onClick={() => setModal(false)}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
