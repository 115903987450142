import './App.scss';

// global styles
import './assets/styles/normalize.css'
import './assets/styles/flex-box.css'
import './assets/styles/global.css'

import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Awards from "./pages/awards/Awards";
import Menu from "./pages/menu/Menu";
import Cards from "./pages/cards/Cards";
import AddCard from "./pages/cards/AddCard";
import Withdraw from "./pages/hisob/withdraw/card/Withdraw";
import Referral from "./pages/referral/Referral";
import SignIn from "./pages/sign/sign-in/SignIn";
import SignUp from "./pages/sign/sign-up/SignUp";
import Overview from "./pages/other/overview/Overview";
import RequireAuth from "./components/RequireAuth";
import History from "./pages/history/History";
import Notfication from "./pages/notification/Notfication";
import EditProfile from "./pages/menu/EditProfile";
import Deposit from "./pages/hisob/deposit/card/Deposit";
import HistoryItem from "./pages/history/HistoryItem";
import {Toaster} from "react-hot-toast";
import Bots from "./pages/investment/bots/Bots";
import BotsIncome from "./pages/investment/bots/BotsIncome";
import BotPage from "./pages/investment/bots/BotPage";
import Page404 from "./pages/other/404/404";
import BotExchange from "./pages/investment/bots/BotExchange";
import Choose from "./pages/hisob/deposit/Choose";
import Payeer from "./pages/hisob/deposit/payeer/Payeer";
import WPayeer from "./pages/hisob/withdraw/payeer/WPayeer";
import Landing from "./pages/landing-page/Landing";
import PSuccess from "./pages/payeer/PSuccess";
import PFail from "./pages/payeer/PFail";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {

  return (
    <div className="App">

      <BrowserRouter>
        <Wrapper>
          <Header />
          <Routes>

            <Route path='/landing' element={<Landing />} />

            <Route path='/overview' element={<Overview />} />

            <Route path='/sign-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/sign-up/:id' element={<SignUp />} />

            <Route path='/success' element={<PSuccess />} />
            <Route path='/fail' element={<PFail />} />

            <Route path='/' element={<RequireAuth />}>
              <Route path='/' element={<Home />} />
              <Route path='/notf' element={<Notfication />} />
              <Route path='/bots' element={<Bots />} />
              <Route path='/bots/income' element={<BotsIncome />} />
              <Route path='/bots/income/:id' element={<BotPage />} />
              <Route path='/bots/exchange' element={<BotExchange />} />
              <Route path='/awards' element={<Awards />} />
              <Route path='/referral' element={<Referral />} />
              <Route path='/menu' element={<Menu />} />
              <Route path='/menu/edit-profile' element={<EditProfile />} />
              <Route path='/cards' element={<Cards />} />
              <Route path='/cards/add' element={<AddCard />} />
              <Route path='/cards/add/:id' element={<AddCard />} />
              <Route path='/history' element={<History />} />
              <Route path='/history/:id/:type/:payType' element={<HistoryItem />} />
              <Route path='/deposit' element={<Choose deposit={true} />} />
              <Route path='/deposit/card' element={<Deposit />} />
              <Route path='/deposit/payeer' element={<Payeer />} />
              <Route path='/withdraw' element={<Choose deposit={false} />} />
              <Route path='/withdraw/card' element={<Withdraw />} />
              <Route path='/withdraw/payeer' element={<WPayeer />} />
            </Route>

            <Route path='/*' element={<Page404 />} />

          </Routes>
        </Wrapper>
      </BrowserRouter>

      <Toaster
          position="top-center"
          reverseOrder={false}
      />
    </div>
  )
}

export default App;
