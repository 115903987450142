import '../Sign.scss'
import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import MyInput from "../UI/MyInput";
import $api from "../../../api";
import {toast} from "react-hot-toast";
import phone from '../../../assets/images/sign-phone.svg'
import lock from '../../../assets/images/sign-lock.svg'

const SignIn = () => {

    const navigate = useNavigate()

    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')

    const signIn = (e) => {
        e.preventDefault()

        const item = {
            phoneNumber: number,
            password: password,
        }
        $api
            .post('/api/robot/v1/auth/sign-in/earner',
                JSON.stringify(item), {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
            .then(res => {
                const accessToken = res.data.data.accessToken
                localStorage.setItem('token', `Bearer ${accessToken}`)
                localStorage.setItem('success', 'true')
                navigate('/')
            })
            .catch(err => {
                if (!err?.response) {
                    toast.error('No Server Response')
                } else {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                }
            })
    }

    return (
        <div className='sign'>
            <div className="container">
                <div className="sign__inner">
                    <div className="sign__titles">
                        <h2 className="sign__title">Welcome back</h2>
                        <p className='sign__txt'>sign in to access your account</p>
                    </div>
                    <form className='sign__form'>
                        <MyInput
                            type='tel'
                            value={setNumber}
                            placeholder='Enter your phone number'
                            img={phone}
                        />
                        <MyInput
                            type='password'
                            value={setPassword}
                            placeholder='Password'
                            img={lock}
                        />
                        <button className='sign__btn' onClick={signIn}>
                            <span>Next</span>
                            <i className="fa-solid fa-chevron-right"/>
                        </button>
                    </form>
                    <p className='sign__desc'>New Member? <Link className='sign__desc-link' to='/sign-up'>Registr now</Link></p>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
