import './Referral.scss'
import React, {useEffect, useState} from 'react';
import refPerson from '../../assets/images/referral-person.svg'
import {useNavigate} from "react-router-dom";
import $api, {API_URL} from "../../api";
import {toast} from "react-hot-toast";
import {formatPrice} from '../../assets/scripts/globalScripts'
import {useTranslation} from "react-i18next";

const Referral = () => {

    const navigate = useNavigate()

    const me = JSON.parse(localStorage.getItem('me'))
    const content = JSON.parse(localStorage.getItem('content'))


    // get my referrals
    const [myReferrals, setMyReferrals] = useState([])
    const [active, setActive] = useState(true)
    const [page, setPage] = useState(0)
    useEffect(() => {
        if (active || !active) setMyReferrals([])

        $api
            .get('/api/robot/v1/referral/referral-data', {
                params: {
                    one: active,
                    page: page,
                    size: 5000
                },
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(res => {
                setMyReferrals(res.data)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    localStorage.removeItem('token')
                    navigate('/sign-in')
                }
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }, [navigate, active, page])


    // // scroll
    // function handleScroll() {
    //     const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
    //     if (scrollTop + clientHeight >= scrollHeight - 10) {
    //         // When the user has scrolled to the bottom, load the next page of data
    //         setPage(prev => prev + 1);
    //     }
    // }

    // useEffect(() => {
    //     // Add an event listener for scrolling
    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, [])
    

    // copy
    const [copy, setCopy] = useState(false)
    const copyToClipBoard = async (e) => {
        await navigator.clipboard.writeText(e)
        setCopy(true)
        setTimeout(() => setCopy(false), 1500)
    }
    const [copy2, setCopy2] = useState(false)
    const copyToClipBoard2 = async (e) => {
        await navigator.clipboard.writeText(e)
        setCopy2(true)
        setTimeout(() => setCopy2(false), 1500)
    }

    const { t } = useTranslation()


    return (
        <div className='container'>
            <div className="ref page">
                <div className="ref__head head">
                    <img className='head__img' src={refPerson} alt="img"/>
                    <div>
                        <div className='head__copy row between align-center'>
                            <span className='head__txt'>{ API_URL + `sign-up/${me?.referralCode}` }</span>
                            <button
                                className='head__btn'
                                onClick={() => copyToClipBoard(API_URL + `sign-up/${me?.referralCode}`)}
                            >
                                { !copy ? t('referral.head.copy_btn') : 'DONE' }
                            </button>
                        </div>
                        <span className='head__txt' style={{margin: '10px 0 1rem 0'}}>{ t('referral.head.your_referral_link') }</span>
                    </div>
                    <div className='row align-center'>
                        <div className='head__copy row between align-center' style={{width: '180px', marginRight: '1rem'}}>
                            <span className='head__txt'>{ me?.referralCode }</span>
                            <button
                                className='head__btn'
                                onClick={() => copyToClipBoard2(me?.referralCode)}
                            >
                                { !copy2 ? t('referral.head.copy_btn') : 'DONE' }
                            </button>
                        </div>
                        <span className='head__txt txt'>{ t('referral.head.your_referral_code') }</span>
                    </div>
                    <div className='head__descs'>
                        <p className='desc margin'>
                            { t('referral.head.desc', {referral1: content?.referralPercentage1, referral2: content?.referralPercentage1Level2}) }
                        </p>
                        <p className='desc'>{ t('referral.head.desc2', {referral1: content?.referralPercentage1}) }</p>
                        <p className='desc'>{ t('referral.head.desc3', {referral2: content?.referralPercentage1Level2}) }</p>
                    </div>
                </div>
                <div className="ref__body body">
                    <div className="body__nav">
                        <button
                            className={`btn ${active ? 'active' : ''}`}
                            onClick={() => setActive(true)}
                        >
                            1-{ t('referral.body.level') }
                        </button>
                        <button
                            className={`btn ${!active ? 'active' : ''}`}
                            onClick={() => setActive(false)}
                        >
                            2-{ t('referral.body.level') }
                        </button>
                    </div>
                    <div className="body__wrapper wrapper">
                        <div className='wrapper__titles'>
                            <div className='row align-center' style={{flexFlow: 'column'}}>
                                <span className='txt'>{ t('referral.body.total_referrals') }</span>
                                <span className='price'>{ myReferrals.data?.referral?.length ? myReferrals.data?.referral?.length : 0 }</span>
                            </div>
                            <div className='row align-center' style={{flexFlow: 'column'}}>
                                <span className='txt'>{ t('referral.body.total_profit') }</span>
                                <span className='price'>{ myReferrals.success ? myReferrals.data?.profit : 0 } uzs</span>
                            </div>
                        </div>
                        <div className="wrapper__txts">
                            <span/>
                            <span>{ t('referral.body.user_name') }</span>
                            <span>{ t('referral.body.register_date') }</span>
                            <span>{ t('referral.body.profit') }</span>
                        </div>
                        <ul className='wrapper__list'>
                            {
                                myReferrals.success ?
                                    myReferrals.data?.referral?.length > 0 ?
                                        myReferrals.data?.referral.map((i, num) => (
                                            <li className='wrapper__item' key={num}>
                                                <span>{ num+1 }</span>
                                                <span style={{textTransform: 'capitalize'}}>{ i.user.name }</span>
                                                <span>{ new Date(i.user.createdAt).toLocaleDateString() }</span>
                                                <span>{ formatPrice(i.profit) } uzs</span>
                                            </li>
                                    ))
                                    : <p className='loading red'>No plans !</p>
                                : <p className='loading'>Loading . . .</p>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Referral;
