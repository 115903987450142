import React, {useState, useEffect} from 'react';
import docs from '../../../assets/images/invest-docs.svg'
import $api from "../../../api";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import {formatPrice} from "../../../assets/scripts/globalScripts";
import {useTranslation} from "react-i18next";


export const Wrapper = ({ i }) => {

    // get img
    const [profileImg, setProfileImg] = useState(null)
    useEffect(() => {
        if (i?.attachmentId) {
            $api
                .get(`/api/robot/v1/attachment/get/${i?.attachmentId}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [i?.attachmentId])


    const { t } = useTranslation()


    return (
        <div className='item__wrapper row between'>
            <div>
                <span className='item__txt'>{ t('plans.body.price_of_plan') }: <span className='font'>{ formatPrice(i.price) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.total_income') }: <span className='font'>{ formatPrice(i.dailyProfit * i.expiredDay) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.daily_income') }: <span className='font'>{ formatPrice(i.dailyProfit) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.days') }: <span className='font'>{ formatPrice(i.expiredDay) }</span></span>
            </div>
                <div className='row align-center' style={{flexFlow: 'column'}}>
                    {
                        profileImg !== null ?
                            <img className='item__img' src={`data:;base64,${profileImg}`} alt="icon"/>
                            : <img className='item__img img' src={docs} alt="icon"/>
                    }
                    <span className='item__img-txt'>{ i.name }</span>
                </div>
        </div>
    )
}


export const Wrapper2 = ({ i }) => {

    // get img
    const [profileImg, setProfileImg] = useState(null)
    useEffect(() => {
        if (i?.robot.attachmentId) {
            $api
                .get(`/api/robot/v1/attachment/get/${i?.robot.attachmentId}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [i?.attachmentId])


    const { t } = useTranslation()


    return (
        <div className='item__wrapper row between' style={{marginBottom: '1rem'}}>
            <div>
                <span className='item__txt'>{ t('plans.body.price_of_plan') }: <span className='font'>{ formatPrice(i.robot.price) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.total_income') }: <span className='font'>{ formatPrice(i.robot.dailyProfit * i.robot.expiredDay) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.daily_income') }: <span className='font'>{ formatPrice(i.robot.dailyProfit) } uzs</span></span>
                <span className='item__txt'>{ t('plans.body.days') }: <span className='font'>{ formatPrice(i.robot.expiredDay) }</span></span>
            </div>
                <div className='row between align-center' style={{flexFlow: 'column'}}>
                    <div className='row align-center' style={{flexFlow: 'column', marginBottom: '1rem'}}>
                        {
                            profileImg !== null ?
                                <img className='item__img' src={`data:;base64,${profileImg}`} alt="icon"/>
                                : <img className='item__img img' src={docs} alt="icon"/>
                        }
                        <span className='item__img-txt'>{ i.name }</span>
                    </div>
                    <Link className='item__btn' to={`/bots/income/:${i.id}`}>Run bot</Link>
                    <span>{ i.acceptedBot }/{ i.robot.acceptCount }</span>
                </div>
        </div>
    )
}


const BotsItem = ({ i, setEffect }) => {

    const [modal, setModal] = useState(false)
    const [count, setCount] = useState(1)

    const buyRobot = () => {
        $api
            .post(`/api/robot/v1/robot/buy/${i.id}`, null, {
                params: { amount: count },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('token')
                }
            })
            .then(() => {
                toast.success('Successfully bought !')
                setEffect(prev => !prev)
                setModal(false)
            })
            .catch(err => {
                err.response.data.errors.map(i => (
                    toast.error(i.errorMsg)
                ))
            })
    }

    const { t } = useTranslation()


    return (
        <li className='item'>
            <div>
                <Wrapper i={i} />
                <div className='row align-center'>
                    <button className='item__btn' onClick={() => setModal(true)}>{ t('plans.body.activate') }</button>
                    <span className='count'>{ i.residual }</span>
                </div>
            </div>
            {
                modal &&
                <div className="modal">
                    <div className="content">
                        <Wrapper i={i} />
                        <div className='content__bottom row between align-center'>
                            <div className='content__sum'>
                                <button className='btn' onClick={() => setCount(prev => prev > 1 ? prev - 1 : prev)}>
                                    <i className="fa-solid fa-minus icon"/>
                                </button>
                                <span className='count'>{ count }</span>
                                <button className='btn' onClick={() => setCount(prev => prev < 10 ? prev + 1 : prev)}>
                                    <i className="fa-solid fa-plus icon"/>
                                </button>
                            </div>
                            <div className='content__btns row'>
                                <button className='btn red' onClick={() => setModal(false)}>Cancel</button>
                                <button className='btn' onClick={buyRobot}>Buy</button>
                            </div>
                        </div>
                    </div>
                    <div className="bg" onClick={() => setModal(false)}/>
                </div>
            }
        </li>
    )
}

export default BotsItem;
