import './Menu.scss'
import React, {useEffect, useState} from 'react';
import MyInput from "../sign/UI/MyInput";
import user from '../../assets/images/sign-user.svg'
import lock from '../../assets/images/sign-lock.svg'
import fileImg from '../../assets/images/sign-profile.svg'
import Head from "../../components/UI/page-head/Head";
import $api from "../../api";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const EditProfile = () => {

    const navigate = useNavigate()

    const [file, setFile] = useState(false)
    const me = JSON.parse(localStorage.getItem('me'))


    // edit profile
    const [name, setName] = useState(me?.name)
    const [password, setPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [profileImg, setProfileImg] = useState(null)
    const [profileImgStatus, setProfileImgStatus] = useState(me?.file?.id)

    const editProfile = (e) => {
        e.preventDefault()

        const item = {
            name: name,
            password: password.length > 0 ? password : null,
            oldPassword: oldPassword.length > 0 ? oldPassword : null,
            attachmentId: file ? file.id : me?.file?.id
        }
            $api
                .put('/api/robot/v1/user/edit-me', item, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token')
                    }
                })
                .then(() => {
                    toast.success('Done !')
                    setTimeout(() => navigate('/'), 1500)
                })
                .catch(err => {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                })
    }


    //get img
    useEffect(() => {
        if (me?.file) {
            $api
                .get(`/api/robot/v1/attachment/get/${me?.file?.id}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                    setProfileImgStatus(true)
                })
        }
    }, [me?.file])
    useEffect(() => {
        if(file?.id) {
            $api
                .get(`/api/robot/v1/attachment/get/${file.id}`, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": localStorage.getItem('token')
                    },
                    responseType: "arraybuffer"
                })
                .then(function (res) {
                    const base64 = btoa(
                        new Uint8Array(res.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setProfileImg(base64)
                })
        }
    }, [file?.id])

    // send file
    const sendFile = (files) => {
        const toastId = toast.loading('Loading . . .')

        const file = files[0]
        const formData = new FormData()
        formData.append('file', file)
        $api
            .post('/api/robot/v1/attachment/upload', formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    "Authorization": localStorage.getItem('token')
                }
            })
            .then(function (res) {
                setFile(res.data.data[0])

                toast.success('Successful loaded !', {
                    id: toastId
                })
                setTimeout(() => toast.dismiss(toastId), 2000)
            })
            .catch(err => {
                if (!err?.response) {
                    toast.error('No Server Response')
                } else {
                    err.response.data.errors.map(i => (
                        toast.error(i.errorMsg)
                    ))
                }

                toast.error('File is not loaded !', {
                    id: toastId
                })
                setTimeout(() => toast.dismiss(toastId), 2000)
            })
    }


    // status img
    const changeStatus = () => {
        setProfileImg(null)
        setProfileImgStatus(false)
    }


    return (
        <div className='container'>
            <div className="edit page">
                <div className="edit__inner">
                    <Head />
                    <form className='edit__form form'>
                        <div className='form__imgs'>
                            {
                                profileImg !== null ?
                                    <div className='label row align-center' style={{flexFlow: 'column'}}>
                                        <img className='img profile-img' src={`data:;base64,${profileImg}`} alt="profile-photo"/>
                                        <span className='img-txt remove' onClick={() => changeStatus()}>Remove photo</span>
                                    </div>
                                    :
                                    <label className='label row align-center' style={{flexFlow: 'column', cursor: 'pointer'}}>
                                        <img className='img' src={fileImg} alt="img"/>
                                        <span
                                            className={`img-txt ${profileImgStatus ? 'remove' : ''}`}
                                            onClick={() => profileImgStatus && changeStatus()}
                                        >
                                            { profileImgStatus ? 'Remove photo' : 'Add photo' }
                                        </span>
                                        <input
                                            className='file'
                                            type="file"
                                            onChange={(e) => sendFile(e.target.files)}
                                        />
                                    </label>
                            }
                        </div>
                        <MyInput
                            type={'text'}
                            placeholder={'Full name'}
                            img={user}
                            defaultValue={name}
                            value={setName}
                        />
                        <MyInput
                            type={'password'}
                            placeholder={'Old password'}
                            img={lock}
                            value={setOldPassword}
                        />
                        <MyInput
                            type={'text'}
                            placeholder={'New password'}
                            img={lock}
                            value={setPassword}
                        />
                        <button className='form__btn' onClick={editProfile}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
