import './Header.scss'
import React from 'react';
import {NavLink, useLocation} from "react-router-dom";

const Header = () => {

    const nav = [
        {
            link: '/',
            icon: 'fa-solid fa-house'
        },
        {
            link: '/bots',
            icon: 'fa-solid fa-chart-line'
        },
        {
            link: '/awards',
            icon: 'fa-solid fa-trophy'
        },
        {
            link: '/referral',
            icon: 'fa-solid fa-users'
        },
        {
            link: '/menu',
            icon: 'fa-solid fa-circle-user'
        },
    ]

    const location = useLocation()

    return (
        <div
            className='header'
            style={{display: location.pathname === '/sign-in' || location.pathname.includes('/sign-up') || location.pathname.includes('/landing') || location.pathname === '/overview' || location.pathname === '/qurilmalar' || location.pathname === '/statistika' ? 'none' : 'block'}}
        >
            <div className="container">
                <nav className='nav'>
                    <ul className="header__list">
                        {
                            nav.map((item, i) => (
                                <li className="header__item" key={i}>
                                    <NavLink className='item__link' to={item.link}>
                                        <i className={`icon ${item.icon}`}/>
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Header;
