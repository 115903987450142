import './Head.scss'
import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Head = ({ plus, nextCount, setPage }) => {

    const navigate = useNavigate()

    const { t } = useTranslation()

    return (
        <div className='page-head row between align-center'>
            <button
                className='navigate'
                onClick={() => nextCount > 1 ? setPage(prev => prev - 1) : navigate(-1)}
            >
                <i className="fa-solid fa-arrow-left icon"/>
            </button>
            {
                plus &&
                <Link className='plus' to='add'>
                    <i className="fa-solid fa-plus icon"/>
                </Link>
            }
            {
                nextCount < 4 &&
                <div className='next'>
                    <span className='txt'>{ t('deposit.step') } { nextCount }/3</span>
                </div>
            }
        </div>
    );
};

export default Head;
