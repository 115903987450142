import './Overview.scss'
import React from 'react';
import Head from "../../../components/UI/page-head/Head";
import img from '../../../assets/images/overview-img.png'

const Overview = () => {
    return (
        <div className='container'>
            <div className="over page">
                <Head />
                <div className="over__body body">
                    <div className='body__titles row between'>
                        <img className='body__img' src={img} alt="img"/>
                        <div>
                            <p className='body__desc center'>
                                An auto trading bot is a software program that automates the process of trading financial instruments such as stocks, currencies, or cryptocurrencies. These bots use algorithms and mathematical models to analyze market data and execute trades based on pre-defined rules and criteria.
                            </p>
                            <p className='body__desc center margin-bot'>
                                The bot scans the market for opportunities and evaluates various technical indicators such as price movements, trading volume, and market trends to make trading decisions. Based on this analysis, the bot can place buy or sell orders automatically without requiring human intervention.
                            </p>
                        </div>
                    </div>
                    <p className='body__desc'>
                        Auto trading bots can also be programmed to follow specific trading strategies, such as scalping, swing trading, or trend following. Traders can set up the bot to execute trades based on their preferred strategy and risk tolerance.
                    </p>
                    <p className='body__desc'>
                        In addition, some auto trading bots use machine learning and artificial intelligence to learn from past trading data and continuously improve their trading performance over time. This allows the bot to adapt to changing market conditions and optimize its trading strategy.
                    </p>
                    <p className='body__desc'>
                        However, it's important to note that auto trading bots are not foolproof and can still be subject to market volatility and unexpected events. It's important to carefully research and evaluate the bot's performance and track record before using it for live trading.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Overview;
