import React, {useState} from 'react';
import deposit2 from '../../../../assets/images/deposit2.svg'
import {toast} from "react-hot-toast";
import {formatCardNumbers} from "../../../../assets/scripts/globalScripts";
import Countdown from 'react-countdown';
import {useTranslation} from "react-i18next";

const Deposit2 = ({ amount, setPage, cardNumber }) => {

    const [modal, setModal] = useState(true)

    // next page
    const goNextPage = () => {
        if (cardNumber.data?.cardNumber !== 0) {
            setModal(false)
            setPage(prev => prev + 1)
        } else {
            toast.error('Error !')
        }
    }


    // copy
    const [copy, setCopy] = useState(false)
    const copyToClipBoard = async (e) => {
        await navigator.clipboard.writeText(e)
        setCopy(true)
        setTimeout(() => setCopy(false), 2000)
    }

    const [copy2, setCopy2] = useState(false)
    const copyToClipBoard2 = async (e) => {
        await navigator.clipboard.writeText(e)
        setCopy2(true)
        setTimeout(() => setCopy2(false), 2000)
    }


    // Timer
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span>00:00</span>
        } else {
            // Render a countdown
            return (
                <p className='timer__desc time'>
                    {minutes}:{seconds}
                </p>
            )
        }
    }


    const { t } = useTranslation()


    return (
        <div className='deposit2'>
            <div className="deposit2__titles">
                <img className='img' src={deposit2} alt="img"/>
                <div className='timer'>
                    <span className='time'>
                        <Countdown date={Date.now() + 600000} renderer={renderer} />
                    </span>
                    <p className='desc'>{ t('deposit.page2.desc') }</p>
                </div>
            </div>
            <div className='form'>
                <div className='form__inps'>
                    <span className='form__txt'>{ t('deposit.page2.label1') }</span>
                    <div className="form__inp row between align-center">
                        <span className='card-number'>{ cardNumber.success ? formatCardNumbers(cardNumber.data?.cardNumber) : '. . .' }</span>
                        <button className='btn copy' onClick={() => copyToClipBoard(cardNumber.data?.cardNumber)}>
                            { copy ? 'Done' : t('deposit.page2.copy') }
                        </button>
                    </div>
                </div>
                <div className='form__inps short'>
                    <span className='form__txt'>{ t('deposit.page2.label2') }</span>
                    <div className="form__inp row between align-center">
                        <span className='card-number'>{ amount } uzs</span>
                        <button className='btn copy' onClick={() => copyToClipBoard2(amount)}>
                            { copy2 ? 'Done' : t('deposit.page2.copy') }
                        </button>
                    </div>
                </div>
            </div>
            <ul className="descs">
                <li className='item'>{ t('deposit.page2.list.item1') }</li>
                <li className='item'>{ t('deposit.page2.list.item2') }</li>
                <li className='item'>{ t('deposit.page2.list.item3') }</li>
                <li className='item'>{ t('deposit.page2.list.item4') }</li>
            </ul>
            <button className='btn green' onClick={goNextPage}>{ t('deposit.page2.paid_btn') }</button>
            {
                modal &&
                <div className='modal'>
                    <div className="content">
                        <span className='content__title'>{ t('deposit.page2.modal.alert') } 🔔</span>
                        <p className="content__desc">
                            { t('deposit.page2.modal.desc') }
                        </p>
                        <button
                            className='content__btn'
                            onClick={() => setModal(false)}
                        >
                            { t('deposit.page2.modal.btn') }
                        </button>
                    </div>
                    <div className="bg" onClick={() => setModal(false)}/>
                </div>
            }
        </div>
    );
};

export default Deposit2;
